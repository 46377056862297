import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function NavigationBar() {
  const { t } = useTranslation();
  const { signOut, user } = useAuthenticator((context) => [context.user]);

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t("TITLE")}
        </Typography>

        <Typography>{user?.username}</Typography>

        <Button
          onClick={signOut}
          color="inherit"
          sx={({ spacing }) => ({ marginLeft: spacing(2) })}
        >
          {t("SIGN_OUT")}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default withAuthenticator(NavigationBar);
