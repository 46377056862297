import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { AccountPlus, DatabaseExport } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 240;

export default function NavigationDrawer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const items = [
    {
      name: t("EXPORT_TRANSACTIONS"),
      to: "/export-transactions",
      icon: <DatabaseExport />,
    },
    {
      name: t("ORGANIZATION_REGISTRATION"),
      to: "/organization-registration",
      icon: <AccountPlus />,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {items.map(({ name, to, icon }) => (
            <ListItemButton
              key={to}
              component={Link}
              to={to}
              selected={pathname.startsWith(to)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
