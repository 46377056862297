import {
  createTheme,
  CssBaseline,
  ThemeProvider as Provider,
  useMediaQuery,
} from "@mui/material";
import { ReactNode, useMemo } from "react";

interface ThemeProviderProps {
  children: ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "none",
              },
            },
          },
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: prefersDarkMode ? "#5069a6" : "#1c3f77",
          },
          secondary: {
            main: "#ffa800",
            contrastText: "#fff",
          },
        },
        typography: {
          fontFamily: "'Noto Sans TC', sans-serif",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <Provider theme={theme}>
      <CssBaseline />
      {children}
    </Provider>
  );
}
