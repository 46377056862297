import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Toolbar } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingBackdrop from "./components/LoadingBackdrop";
import NavigationBar from "./components/NavigationBar";
import NavigationDrawer from "./components/NavigationDrawer";
import "./i18n";
import QueryClientProvider from "./providers/QueryClientProvider";
import ThemeProvider from "./providers/ThemeProvider";

const ExportTransactions = lazy(() => import("./pages/ExportTransactions"));
const OrganizationRegistration = lazy(
  () => import("pages/OrganizationRegistration")
);
const OrganizationRegistrationDetail = lazy(
  () => import("pages/OrganizationRegistration/OrganizationRegistrationDetail")
);

function App() {
  return (
    <Suspense fallback={<LoadingBackdrop />}>
      <ThemeProvider>
        <SnackbarProvider>
          <QueryClientProvider>
            <BrowserRouter>
              <Box sx={{ display: "flex" }}>
                <NavigationBar />
                <NavigationDrawer />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <Toolbar />
                  <Routes>
                    <Route
                      path="/export-transactions"
                      element={<ExportTransactions />}
                    />
                    <Route
                      path="/organization-registration"
                      element={<OrganizationRegistration />}
                    />
                    <Route
                      path="/organization-registration/:id"
                      element={<OrganizationRegistrationDetail />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/export-transactions" />}
                    />
                  </Routes>
                </Box>
              </Box>
            </BrowserRouter>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
