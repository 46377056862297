import { Amplify, Auth } from "aws-amplify";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

axios.defaults.baseURL = process.env.REACT_APP_API_GATEWAY_URL;

axios.interceptors.request.use(async ({ headers, ...config }) => {
  const session = await Auth.currentSession();

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
    ...config,
  };
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
