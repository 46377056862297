import { Backdrop, CircularProgress } from "@mui/material";

interface LoadingBackdropProps {
  loading?: boolean;
}

export default function LoadingBackdrop({ loading }: LoadingBackdropProps) {
  return (
    <Backdrop
      open={loading ?? true}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress />
    </Backdrop>
  );
}
