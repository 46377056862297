import LoadingBackdrop from "components/LoadingBackdrop";
import { ReactNode } from "react";
import {
  QueryClient,
  QueryClientProvider as Provider,
  useIsFetching,
  useIsMutating,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function QueryLoadingBackdrop() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return <LoadingBackdrop loading={isFetching > 0 || isMutating > 0} />;
}

interface QueryClientProviderProps {
  children: ReactNode;
}

export default function QueryClientProvider({
  children,
}: QueryClientProviderProps) {
  return (
    <Provider client={queryClient}>
      <QueryLoadingBackdrop />
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  );
}
